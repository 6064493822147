import React, { useEffect } from 'react';
import medhaLogo from '../images/medha.png';
import { useLocation } from 'react-router-dom';

const ThankyouPage = () => {
    const location = useLocation();
    const donationFrom = localStorage.getItem('donationFrom')

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            localStorage.removeItem('donationFrom')
        }, 1500)
        return () => {
            clearTimeout(debounceTimer)
        }
    }, [])

    return (
        <div className='thankyou-page'>
            <div className='p-lg-5'>
                {donationFrom === 'studentDonateForm' ? <div className="container min-vh-60 d-flex flex-column justify-content-center align-items-center text-center">
                    <div className="card shadow-lg p-0">
                        <div className='thankyou d-flex justify-content-center'>
                                <img src={medhaLogo} className='logo ' alt="Medha Logo" />
                        </div>

                        <div className="card-body">
                            <h1 className="display-5 text-gray mb-3">Thank You!</h1>
                            <p className="lead">Thanks for your generous donation. Your support helps us continue our mission.</p>
                            <p className="mt-4">We truly appreciate your contribution.</p>
                        </div>
                    </div>
                </div> : <div className='header-thankyou'>
                    <div className='thankyou d-flex justify-content-between'>
                        <div>
                            <img src={medhaLogo} className='logo ' alt="Medha Logo" />
                        </div>
                        <div className='sign-up-text text-white display-6 d-flex flex-column justify-content-center px-2'>SIGN UP SUCCESSFUL</div>
                    </div>

                    <div className='fz-24 p-lg-2'>
                        <div className='text-center'>
                            <div>
                                Name:{location?.state?.name || 'N/A'}
                            </div>
                            <div>Date: {new Date().toLocaleDateString()}</div>
                        </div>
                        <div className='text-center'>Email id: {location?.state?.email || 'N/A'}</div>
                    </div>
                    <div className='text-center fz-24 pb-4'><b>Student Id: {location?.state?.id || 'N/A'}</b></div>
                    <div className='footer-thankyou text-center py-4 fz-24'>
                        <p><b>You will receive a confirmation email with your student ID.</b></p>
                        <p>In case you require a receipt of the transaction, mail us at <a href="mailto:finance@medha.org.in">finance@medha.org.in</a></p>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default ThankyouPage;
